var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "q-pa-md" },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 moc-complete" },
          [
            _c("c-textarea", {
              attrs: {
                disabled: true,
                editable: _vm.editable,
                label: "LBL0001973",
                name: "mocCompleteOpinion",
              },
              model: {
                value: _vm.moc.mocCompleteOpinion,
                callback: function ($$v) {
                  _vm.$set(_vm.moc, "mocCompleteOpinion", $$v)
                },
                expression: "moc.mocCompleteOpinion",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "q-timeline",
        { staticClass: "moc-timeline", attrs: { color: "green-4" } },
        _vm._l(_vm.items, function (item, idx) {
          return _c(
            "q-timeline-entry",
            {
              key: idx,
              class: [idx === 0 ? "fix-header" : ""],
              attrs: { heading: item.heading, icon: item.icon },
              scopedSlots: _vm._u(
                [
                  item.title
                    ? {
                        key: "title",
                        fn: function () {
                          return [_c("b", [_vm._v(_vm._s(item.title))])]
                        },
                        proxy: true,
                      }
                    : null,
                  item.subtitle
                    ? {
                        key: "subtitle",
                        fn: function () {
                          return [_vm._v(" " + _vm._s(item.subtitle) + " ")]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            },
            [
              !item.heading
                ? _c("q-slide-transition", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: item.visible,
                            expression: "item.visible",
                          },
                        ],
                      },
                      [
                        _c(item.component, {
                          tag: "component",
                          attrs: {
                            param: _vm.param,
                            moc: _vm.moc,
                            disabled: true,
                            onePage: true,
                          },
                          on: {
                            "update:param": function ($event) {
                              _vm.param = $event
                            },
                            "update:moc": function ($event) {
                              _vm.moc = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }